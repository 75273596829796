$desktop-width: 1360px;
$laptop-width: 1024px;
$tablet-l-width: 768px;
$tablet-m-width: 578px;
$tablet-s-width: 476px;
$mobile-l-width: 425px;
$mobile-m-width: 375px;
$mobile-s-width: 320px;

@media only screen and (max-width: calc($mobile-l-width + 1px)) and (min-width: $mobile-s-width) {
  .static-navbar {
    height: 66px !important;
  }
}
