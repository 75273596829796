$desktop-width: 1360px;
$laptop-width: 1024px;
$tablet-l-width: 768px;
$tablet-m-width: 578px;
$tablet-s-width: 476px;
$mobile-l-width: 425px;
$mobile-m-width: 375px;
$mobile-s-width: 320px;

#main-static {
  @media only screen and (min-width: $desktop-width) {
    background-color: blue;
  }

  @media only screen and (max-width: $desktop-width) and (min-width: $laptop-width) {
    background-color: red;
  }
}

@media only screen and (max-width: $tablet-l-width) and (min-width: calc($mobile-l-width + 1px)) {
  .form-post-container {
    padding-left: 39px !important;
  }

  .form-label-field {
    flex-direction: column;
    gap: 10px !important;
    padding-right: 0px !important;
  }

  .form-label,
  .form-field {
    width: 100% !important;
  }

  .form-label > p {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: calc($mobile-l-width + 1px)) and (min-width: $mobile-s-width) {
  .form-post-container {
    padding-left: 9px !important;
    padding-right: 9px !important;
  }

  .form-label-field {
    flex-direction: column;
    gap: 10px !important;
    padding-right: 0px !important;
  }

  .form-label,
  .form-field {
    width: 100% !important;
  }

  .form-label > p {
    padding-top: 0px !important;
  }
}
