// @font-face {
//   font-family: "Mabry Pro";
//   src: url("../assets/fonts/MabryPro/Mabry-Pro.woff") format("woff"),
//     url("../assets/fonts/MabryPro/Mabry-Pro.woff2") format("woff2");
//   font-weight: normal;
// }

// @font-face {
//   font-family: "Mabry Pro";
//   src: url("../assets/fonts/MabryPro/Mabry-Pro-Light.woff") format("woff"),
//     url("../assets/fonts/MabryPro/Mabry-Pro-Light.woff") format("woff2");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "Mabry Pro";
//   src: url("../assets/fonts/MabryPro/Mabry-Pro-Medium.woff") format("woff"),
//     url("../assets/fonts/MabryPro/Mabry-Pro-Medium.woff2") format("woff2");
//   font-weight: 700;
// }

// @font-face {
//   font-family: "Mabry Pro";
//   src: url("../assets/fonts/MabryPro/Mabry-Pro-Bold.woff") format("woff"),
//     url("../assets/fonts/MabryPro/Mabry-Pro-Bold.woff2") format("woff2");
//   font-weight: bold;
// }

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Outfit";
  src: url("../assets/fonts/Outfit/Outfit-Black.ttf") format("truetype");
  font-weight: 900;
}

@mixin font-smoothing($value: on) {
  @if $value ==on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

body {
  font-family: "Outfit" !important;
  @include font-smoothing(on);
  margin: 0px;
  height: 100vh;
}

#root {
  height: 100%;
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #F4F4F4; // rgba(2, 85, 91,1);
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #A1A1A1; // rgba(3, 132, 141, 1);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #737373; // rgba(3, 132, 141, 0.7);
} */
