#message-contact:focus-visible {
  outline-color: #A1A1A1;
}

#message-contact[placeholder]:empty::before {
    content: attr(placeholder);
    color: #A1A1A1; 
}

#message-contact[placeholder]:empty:focus::before {
    content: "";
}
